import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

function TaskManager({ tasks, setTasks, toggleTheme, theme }) {
  const navigate = useNavigate();

  const goToAddTask = () => {
    navigate('/add-task');
  };

  const viewTaskDetail = (taskId) => {
    navigate(`/add-task?id=${taskId}`);
  };

  const deleteTask = (index, event) => {
    event.stopPropagation(); // Prevent click event from triggering viewTaskDetail
    setTasks(tasks.filter((_, i) => i !== index));
  };

  // Function to change the color of the task
  const changeColor = (index) => {
    setTasks((prevTasks) => {
      const newTasks = [...prevTasks];
      const currentColor = newTasks[index].color;

      // Cycle through colors: red -> green -> orange -> red
      const nextColor = currentColor === '#FF4444' ? '#00C851' : currentColor === '#00C851' ? '#FFBB33' : '#FF4444';
      newTasks[index].color = nextColor;

      return newTasks;
    });
  };

  return (
    <div className={`card ${theme}`}>
      <div className="card-left">
        <div className="text-container">
          <h2>U</h2>
          <p>Task</p>
        </div>

        <label className="switch">
          <input type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
          <span className="slider" />
        </label>
        <p className="ver">Version 1.0.0</p>
      </div>

      <div className="card-right">
        <div className="task-list">
          {tasks.length > 0 ? (
            tasks.map((task, index) => (
              <div
                key={index}
                className="task-card"
                onClick={() => viewTaskDetail(index)} // Keep the click for viewing task details
              >
                <div className="color-circle" onClick={(e) => { e.stopPropagation(); changeColor(index); }} style={{ backgroundColor: task.color }} />
                <h3>{task.title}</h3>
                <button
                  className="delete-button"
                  onClick={(e) => deleteTask(index, e)}
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              </div>
            ))
          ) : (
            <p className="no-tasks-message">No tasks available</p>
          )}
        </div>
      </div>

      <button className="add-task-button" onClick={goToAddTask}>
        +
      </button>
    </div>
    
  );
}

export default TaskManager;
