import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './App.css';

function AddTask({ setTasks, tasks, toggleTheme, theme }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('id');

  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');

  useEffect(() => {
    if (taskId) {
      const task = tasks[taskId];
      if (task) {
        setTaskTitle(task.title);
        setTaskDescription(task.description);
      }
    }
  }, [taskId, tasks]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initialize the new task with default color 'red'
    const newTask = { 
      title: taskTitle, 
      description: taskDescription, 
      color: '#FF4444' // Default color is set to 'red'
    };

    if (taskId) {
      // Update existing task
      setTasks(tasks.map((task, index) => (
        index === parseInt(taskId) ? { ...task, ...newTask } : task
      )));
    } else {
      // Add new task
      setTasks((prevTasks) => [...prevTasks, newTask]);
    }

    navigate('/');
  };

  return (
    <div className={`card ${theme}`}>
      <div className="card-left">
        <div className="text-container">
          <h2>U</h2>
          <p>Task</p>
        </div>

        <label className="switch">
          <input type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
          <span className="slider" />
        </label>
        <p className="ver">Version 1.0.0</p>
      </div>

      <div className="card-right">
        <form onSubmit={handleSubmit} className="task-form">
          <input
            type="text"
            placeholder="Task Name"
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            required
          />
          <textarea
            placeholder="Task Description"
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
            required
          />
          <button type="submit" className="submit-button">
            {taskId ? 'Update Task' : 'Save Task'}
          </button>
          <button 
            type="button" 
            onClick={() => navigate('/')} 
            className="back-button"
          >
            Back to Task List
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddTask;
