import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TaskManager from './TaskManager';
import AddTask from './AddTask';
import './App.css';

function App() {
  // Load tasks from local storage or initialize with an empty array
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    return savedTasks ? JSON.parse(savedTasks) : [];
  });

  // Load theme from local storage or default to 'light'
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'light';
  });

  // Save tasks to local storage whenever tasks state changes
  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  // Save theme to local storage whenever theme state changes
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light')); // Toggle between light and dark
  };

  return (
    <div className={`app ${theme}`}> {/* Apply theme class */}
      <Router>
        <Routes>
          <Route path="/" element={<TaskManager tasks={tasks} theme={theme} toggleTheme={toggleTheme} setTasks={setTasks} />} />
          <Route path="/add-task" element={<AddTask setTasks={setTasks} tasks={tasks} theme={theme} toggleTheme={toggleTheme} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
